header {
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 30px;
  display: flex;
  justify-content: space-between;
  margin: 0 10px;

  .logo-container {
    align-items: center;
    display: flex;
    height: 70px;
    text-align: center;
    width: 300px;

    .app-logo {
      height: 3rem;

      @media (max-width: 568px) {
        height: 1.7rem;
      }
    }
  }

  .navbar {
    display: flex;
    gap: 20px;

    &__items {
      color: #000;
      text-decoration: none;
      font-weight: bold;
      display: flex;
      gap: 5px;
      align-items: center;

      .cls-1 {
        fill: none;
      }

      #chat {
        width: 19px;
        height: 19px;
      }

      &:hover,
      &:visited {
        color: #00a0e3;
      }

      @media (max-width: 568px) {
        & span {
          display: none;
        }
      }
    }
  }
}

section {
  align-items: center;
  background: linear-gradient(45deg, #00a0e3, transparent);
  display: flex;
  height: calc(100vh - 70px);
  justify-content: center;
  width: 100%;

  h1 {
    margin: 0;

    .typing {
      position: relative;

      span {
        content: '';
        animation: blink 1.5s infinite;
        animation-fill-mode: both;
        height: 10px;
        width: 10px;
        background: #3b5998;
        position: absolute;
        left: 5px;
        bottom: 7px;
        border-radius: 50%;

        &:nth-child(2) {
          animation-delay: .2s;
          margin-left: 10px * 1.5;
        }

        &:nth-child(3) {
          animation-delay: .4s;
          margin-left: 10px * 3;
        }
      }
    }


    @keyframes blink {
      0% {
        opacity: .1;
      }

      20% {
        opacity: 1;
      }

      100% {
        opacity: .1;
      }
    }
  }
}